<template>
  <div>
    <b-table striped hover sort-icon-right :fixed="isXs"
        class="mb-2 mb-sm-3"
        :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" no-local-sorting @sort-changed="sortChanged"
        v-if="items.length" @row-clicked="onRowClick">
      <template #cell(state)="data">
        <span v-b-tooltip="{trigger: istouch()?'click':'hover', delay: 10}" :title="$t('res.s.'+data.value.toLowerCase())"><Icon :type="data.value" :isButton="false" /></span>
      </template>
      <template #cell(seats)="data">
        <span>{{ getFor(data.value) }}{{ data.item.userName != null ? `, `+data.item.userName : '' }}</span>
      </template>
      <template #cell(tableName)="{item, index}">
        <span v-if="index !== editIndex">{{ item.tableName }}</span>
        <span v-else-if="!isEditing && index === editIndex">{{ item.tableName }}</span>
        <TablesDropdown v-else @input="onTableSet($event,item)" :value="item.tableUuid4" :placeHolder="item.tableName" :autoload="true" :id="`custTablDd`+suffix+index" form="empty" />
      </template>
      <template #cell(startAt)="data">
        <span>{{ getDateTime(data.value) }}</span>
      </template>
      <template #cell(duration)="data">
        <span>{{ getDur(data.value) }}</span>
      </template>
      <template #cell(uuid4)="data">
        <ButtonSet :isDd="isXs" :useCols="false" :suffix="suffix" :id="items.indexOf(data.item)"
            :item="data.item" :showReject="showReject" :showAccept="showAccept" :showDelete="showDelete" :showConfirm="showConfirm" :showDispute="showDispute" :showResolve="showResolve"
            @success="onConfirmation()" :saveResult="true"
            :beforeShow="showShow" beforeVariant="primary" :beforeTo="`/reservations/`+data.item.uuid4" beforeSuffix="Show" beforeIcon="shw">
          <template #before>
            {{ $t('btn.shw') }}
          </template>
        </ButtonSet>
      </template>
    </b-table>
    <b-row fluid v-if="items.length">
      <b-col v-if="showPagination" class="d-flex align-items-end">
        <Pagination ref="pagi" @click="changePage" />
      </b-col>
      <b-col>
        <slot name="nextToPagi" />
      </b-col>
    </b-row>
    <b-card v-if="!items.length">
      <b-link v-if="noHitLink" :to="noHitLink">{{ noHitText }}</b-link>
      <span v-else>{{ noHitText }}</span>
    </b-card>
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { utcToZonedTime } from 'date-fns-tz';
import { REST } from '@/components/RestCall';
import Query from '@/components/RestQuery';
import Pagination from '@/components/Pagination';
import ButtonSet from '@/components/reservations/ButtonSet';
import TablesDropdown from '@/components/shared/TablesDropdown';

export default {
  data() {
    return {
      sortBy: 'startAt',
      sortDesc: false,
      // items and pagination would need to be moved to store to survive a "back" from showDetails
      items: [],
      isEditing: false,
      isExecuting: false,
      editIndex: -1,
    }
  },
  created() {
    // values would need to be moved to store to survive "F5"
    [this.sortDesc, this.sortBy, ] = Query.fetchSort(this.query, this.sortDesc, this.sortBy);
    Query.setSort(this.query, this.sortDesc, this.sortBy, 'uuid4');
    this.getResData();
  },
  computed: {
    fields() {
      return [
        { key: 'state', label: '', class: 'px-2 px-sm-3', thStyle: 'width: 25px;' },
        { key: 'seats', label: this.$t('tab.o'), class: 'px-1 px-sm-3', thStyle: 'width: 30%;', sortable: true },
        { key: 'tableName', label: this.$t('tab.q'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: 25%;', sortable: true },
        { key: 'startAt', label: this.$t('tab.b'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: 25%;', sortable: true },
        { key: 'duration', label: this.$t('tab.m'), class: 'px-1 px-sm-3', thStyle: 'width: 20%;', sortable: true },
        { key: 'uuid4', label: this.isXs?'':this.$t('tab.a'), class: 'pl-2 pl-sm-3 pr-0', thStyle: 'width: 35px;' },
      ];
    },
    uiSett() {
      return this.$store.getters.settings;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
  },
  methods: {
    // onClose() {
    //   this.currentItem = null;
    // },
    onConfirmation() {
      // no pagination change necessary: state change does not affect order, delete will auto-update, add does not happen
      this.getResData(); // update full table, maybe just delete one line better
    },
    sortChanged(ctx) {
      if (ctx.sortBy == '') { return; }
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      Query.setSort(this.query, ctx.sortDesc, ctx.sortBy, 'uuid4');
      if (this.showPagination) {
        this.$refs.pagi.reset();
      }
      Query.setPagi(this.query, null, this.uiSett.visiblePerPage);
      this.getResData();
    },
    changePage(nextString) {
      Query.setPagi(this.query, nextString, this.uiSett.visiblePerPage);
      this.getResData();
    },
    getDateTime(v) {
      if (!v) return '';
      return this.$d(utcToZonedTime(v+'Z', this.tz), 'as');
    },
    getDur(v) {
      return this.$t('res.dur', [this.$n(v, 'd')]);
    },
    getFor(v) {
      return this.$t('res.rfo', [v.toString()]);
    },
    getResData() {
      REST.get('/customers/' + this.$store.getters.uuid + '/reservations', this.getFormattedQuery()
        ).then(resp => {
          this.items = resp.d.data;
          if (this.showPagination) {
            this.$nextTick(() => { // refs not yet existing (rendered) without nextTick
              if (resp.d.links.next !== '') {
                this.$refs.pagi.update(resp.d.links.next);
              }
              this.$emit('loaded', { hasContent: true, hasMore: this.$refs.pagi.hasMore() });
            });
          }
        }).catch(e => {
          this.items = [];
          // catch 404 warning: user may not have added favs yet
          if (e.code != 404) {
            this.$store.commit('showWarn',e.message);
          }
          // if (this.showPagination) {
          //   this.$nextTick(() => {
              this.$emit('loaded', { hasContent: false, hasMore: false });
          //   });
          // }
        })
    },
    getFormattedQuery() {
      // generate "current" query object, based on supplied template and params
      let newQuery = {...this.query};
      return { params: Query.replaceMagic(newQuery) };
    },
    istouch() {
      return (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
    },
    onRowClick(item, idx) {
      if (!this.isExecuting) {
        this.editIndex = idx;
        this.isEditing = true;
      }
    },
    onTableSet(event, item) {
      if (!this.isExecuting) {
        this.isExecuting = true;
        REST.patch('/reservations/' + item.uuid4, { action: 'EDIT', tableUuid4: event.tableUuid4 })
          .then(resp => {
            this.$store.commit('showSuccess', resp.d.message);
            item.tableName = event.tableName;
            item.tableUuid4 = event.tableUuid4;
            // this.items[idx].tableName = event.tableName;
            // this.items[idx].tableUuid4 = event.tableUuid4;
          }).catch(e => {
            this.$store.commit('showWarn',e.message);
          }).finally(() => {
            this.isEditing = false;
            this.isExecuting = false;
            this.editIndex = -1;
          })
      }
    },
  },
  // filters: {
  //   firstChar: function(value) {
  //     if (!value) return '';
  //     return value.toString().substring(0,1);
  //   }
  // },
  props: {
    query: {
      type: Object,
      required: true,
    },
    noHitText: {
      type: String,
      default: 'No reservations.'
    },
    noHitLink: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      required: true,
    },
    showShow: {
      type: Boolean,
      default: false
    },
    showReject: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    showAccept: {
      type: Boolean,
      default: false
    },
    showConfirm: {
      type: Boolean,
      default: false
    },
    showDispute: {
      type: Boolean,
      default: false
    },
    showResolve: {
      type: Boolean,
      default: false
    },
    showPagination: {
      type: Boolean,
      default: false
    },
  },
  components: {
    TablesDropdown,
    ButtonSet,
    Pagination,
    Icon,
  },
}
</script>
