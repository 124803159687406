<template>
  <div>
    <component :is="type" v-if="useCols" class="mr-n2 mr-sm-n3">
      <b-col :class="ac" v-if="beforeShow">
        <RButton :variant="beforeVariant" :id="id" :suffix="beforeSuffix"
            @click.native="$emit('beforeClick')" :to="beforeTo">
          <span class="text-nowrap"><Icon v-if="beforeIcon!==null" :type="beforeIcon" /> <slot name="before" /></span>
        </RButton>
      </b-col>
      <b-col :class="ac" v-if="isShownCancel">
        <RButton variant="secondary" :id="id" suffix="Cnl"
            @click.native="onAction(item, actionTypes.cancel)">
          <span class="text-nowrap"><Icon type="can" /> {{ actionButtonTexts[actionTypes.cancel] }}</span>
        </RButton>
      </b-col>
      <b-col :class="ac" v-if="isShownReject">
        <RButton variant="secondary" :id="id" suffix="Rej"
            @click.native="onAction(item, actionTypes.reject)">
          <span class="text-nowrap"><Icon type="rej" /> {{ actionButtonTexts[actionTypes.reject] }}</span>
        </RButton>
      </b-col>
      <b-col :class="ac" v-if="isShownAccept">
        <RButton variant="primary" :id="id" suffix="Acc"
            @click.native="onAction(item, actionTypes.accept)">
          <span class="text-nowrap"><Icon type="acc" /> {{ actionButtonTexts[actionTypes.accept] }}</span>
        </RButton>
      </b-col>
      <b-col :class="ac" v-if="isShownConfirm">
        <RButton variant="primary" :id="id" suffix="Cnf"
            @click.native="onAction(item, actionTypes.confirm)">
          <span class="text-nowrap"><Icon type="cnf" /> {{ actionButtonTexts[actionTypes.confirm] }}</span>
        </RButton>
      </b-col>
      <b-col :class="ac" v-if="isShownDispute">
        <RButton variant="secondary" :id="id" suffix="Dis"
            @click.native="onAction(item, actionTypes.dispute)">
          <span class="text-nowrap"><Icon type="dis" /> {{ actionButtonTexts[actionTypes.dispute] }}</span>
        </RButton>
      </b-col>
      <b-col :class="ac" v-if="isShownProtest">
        <RButton variant="secondary" :id="id" suffix="Prt"
            @click.native="onAction(item, actionTypes.protest)">
          <span class="text-nowrap"><Icon type="prt" /> {{ actionButtonTexts[actionTypes.protest] }}</span>
        </RButton>
      </b-col>
      <b-col :class="ac" v-if="isShownResolve">
        <RButton variant="primary" :id="id" suffix="Rsv"
            @click.native="onAction(item, actionTypes.resolve)">
          <span class="text-nowrap"><Icon type="rsv" /> {{ actionButtonTexts[actionTypes.resolve] }}</span>
        </RButton>
      </b-col>
      <b-col :class="ac" v-if="afterShow">
        <RButton :variant="afterVariant" :id="id" :suffix="afterSuffix"
            @click.native="$emit('afterClick')" :to="afterTo">
          <span class="text-nowrap"><Icon v-if="afterIcon!==null" :type="afterIcon" /> <slot name="after" /></span>
        </RButton>
      </b-col>
    </component>

    <component :is="isDd?`b-dropdown`:`b-btn-group`" v-else-if="isShown"
        :vertical="isDd?false:vertical" text="" :right="isDd?true:undefined">
      <component :is="isDd?`b-dropdown-item`:`RButton`" v-if="beforeShow" :variant="beforeVariant" :id="id" :suffix="beforeSuffix"
          @click.native="$emit('beforeClick')" :to="beforeTo">
        <span class="text-nowrap"><Icon v-if="beforeIcon!==null&&(isXs||isMd)" :type="beforeIcon" :isButton="!isDd" :isNav="isDd" /> <slot name="before" /></span>
      </component>
      <component :is="isDd?`b-dropdown-item`:`RButton`" variant="secondary" :id="id" suffix="Cnl"
          @click.native="onAction(item, actionTypes.cancel)"
          v-if="isShownCancel">
        <span class="text-nowrap"><Icon v-if="isXs||isMd" type="can" :isButton="!isDd" :isNav="isDd" /> {{ actionButtonTexts[actionTypes.cancel] }}</span>
      </component>
      <component :is="isDd?`b-dropdown-item`:`RButton`" variant="secondary" :id="id" suffix="Rej"
          @click.native="onAction(item, actionTypes.reject)"
          v-if="isShownReject">
        <span class="text-nowrap"><Icon v-if="isXs||isMd" type="rej" :isButton="!isDd" :isNav="isDd" /> {{ actionButtonTexts[actionTypes.reject] }}</span>
      </component>
      <component :is="isDd?`b-dropdown-item`:`RButton`" variant="primary" :id="id" suffix="Acc"
          @click.native="onAction(item, actionTypes.accept)"
          v-if="isShownAccept">
        <span class="text-nowrap"><Icon v-if="isXs||isMd" type="acc" :isButton="!isDd" :isNav="isDd" /> {{ actionButtonTexts[actionTypes.accept] }}</span>
      </component>
      <component :is="isDd?`b-dropdown-item`:`RButton`" variant="primary" :id="id" suffix="Cnf"
          @click.native="onAction(item, actionTypes.confirm)"
          v-if="isShownConfirm">
        <span class="text-nowrap"><Icon v-if="isXs||isMd" type="cnf" :isButton="!isDd" :isNav="isDd" /> {{ actionButtonTexts[actionTypes.confirm] }}</span>
      </component>
      <component :is="isDd?`b-dropdown-item`:`RButton`" variant="secondary" :id="id" suffix="Dis"
          @click.native="onAction(item, actionTypes.dispute)"
          v-if="isShownDispute">
        <span class="text-nowrap"><Icon v-if="isXs||isMd" type="dis" :isButton="!isDd" :isNav="isDd" /> {{ actionButtonTexts[actionTypes.dispute] }}</span>
      </component>
      <component :is="isDd?`b-dropdown-item`:`RButton`" variant="secondary" :id="id" suffix="Prt"
          @click.native="onAction(item, actionTypes.protest)"
          v-if="isShownProtest">
        <span class="text-nowrap"><Icon v-if="isXs||isMd" type="prt" :isButton="!isDd" :isNav="isDd" /> {{ actionButtonTexts[actionTypes.protest] }}</span>
      </component>
      <component :is="isDd?`b-dropdown-item`:`RButton`" variant="primary" :id="id" suffix="Rsv"
          @click.native="onAction(item, actionTypes.resolve)"
          v-if="isShownResolve">
        <span class="text-nowrap"><Icon v-if="isXs||isMd" type="rsv" :isButton="!isDd" :isNav="isDd" /> {{ actionButtonTexts[actionTypes.resolve] }}</span>
      </component>
      <component :is="isDd?`b-dropdown-item`:`RButton`" v-if="afterShow" :variant="afterVariant" :id="id" :suffix="afterSuffix"
          @click.native="$emit('afterClick')" :to="afterTo">
        <span class="text-nowrap"><Icon v-if="afterIcon!==null&&(isXs||isMd)" :type="afterIcon" :isButton="!isDd" :isNav="isDd" /> <slot name="after" /></span>
      </component>
    </component>

    <ModalYesNoGeneric :id="`resBtnAct` + suffix + id" :title="modalHeaderText"
        :btnTextReset="$t('btn.a')" btnIconReset="a"
        :btnTextSubmit="modalBtnText" :btnIconSubmit="modalBtnIcon" btnVariantSubmit="secondary"
        @reset="onClose()" @submit="onConfirmation()" />
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { utcToZonedTime } from 'date-fns-tz';
import { REST } from '@/components/RestCall';
import RButton from '@/components/reservations/Button';
import ModalYesNoGeneric from '@/components/ModalYesNoGeneric';


// <b-col :class="ac" v-if="isShownDelete">
//   <RButton variant="secondary" :id="id" suffix="Del"
//       @click.native="onAction(item, actionTypes.delete)">
//     <span class="text-nowrap"><Icon type="del" /> {{ actionButtonTexts[actionTypes.delete] }}</span>
//   </RButton>
// </b-col>
// <component :is="isDd?`b-dropdown-item`:`RButton`" variant="secondary" :id="id" suffix="Del"
//     @click.native="onAction(item, actionTypes.delete)"
//     v-if="isShownDelete">
//   <span class="text-nowrap"><Icon v-if="isXs||isMd" type="del" :isButton="!isDd" :isNav="isDd" /> {{ actionButtonTexts[actionTypes.delete] }}</span>
// </component>

const lactionTypes = {
  none:   0,
  accept: 1,
  cancel: 2,
  reject: 4,
// delete: 8,
  confirm: 8,
  dispute: 16,
  protest: 32,
  resolve: 64,
}

const lActionCommands = {
  0: ['NONE', ''],
  1: ['ACCEPT', 'acc'],
  2: ['CANCEL', 'can'],
  4: ['REJECT', 'rej'],
// 8: ['DELETE', 'del'],
  8: ['CONFIRM', 'cnf'],
  16: ['DISPUTE', 'dis'],
  32: ['PROTEST', 'prt'],
  64: ['RESOLVE', 'rsv'],
}

const earliestDisputeAfter = 15;

export default {
  data() {
    return {
      ac: {
        'col-auto': true,
        'mr-2': true,
        'mr-sm-3': true,
        'px-0': true
      },
      actionTypes: lactionTypes,
      // actionButtonTexts: lActionButtonTexts,

      aType: lactionTypes.none,
      modalHeaderText: '',
      modalBtnText: null,
      modalBtnIcon: null,
    }
  },
  computed: {
    actionButtonTexts() {
      return {
        0: '',
        1: this.$t('btn.acc'),
        2: this.$t('btn.can'),
        4: this.$t('btn.rej'),
        // 8: this.$t('btn.del'),
        8: this.$t('btn.cnf'),
        16: this.$t('btn.dis'),
        32: this.$t('btn.prt'),
        64: this.$t('btn.rsv')
      };
    },
    // hasBefore() {
    //   return !!this.$slots.before || !!this.$scopedSlots.before;
    // },
    // hasAfter() {
    //   return !!this.$slots.after || !!this.$scopedSlots.after;
    // },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    btnType() {
      return this.isDd ? 'b-dropdown-item' : 'b-button';
    },
    isShown() {
      return this.beforeShow || this.isShownCancel || this.isShownDelete || this.afterShow;
    },
    isShownCancel() {
      return this.isUser && this.showCancel && ['CREATED','ACCEPTED'].includes(this.item.state);
    },
    isShownReject() {
      return !this.isUser && this.showReject && ['CREATED','ACCEPTED'].includes(this.item.state);
    },
    isShownDelete() { // perma off
      return false;
      // return this.showDelete && ['REJECTED','CANCELED','LOCKED'].includes(this.item.state);
    },
    isShownAccept() {
      return !this.isUser && this.showAccept && ['CREATED'].includes(this.item.state);
    },
    isShownConfirm() {
      return !this.isUser && this.showConfirm && ['LOCKED'].includes(this.item.state);
    },
    isShownDispute() {
      return !this.isUser && this.showDispute && ['LOCKED'].includes(this.item.state) && this.valDisTime(this.item);
    },
    isShownProtest() {
      return this.isUser && this.showProtest && ['DISPUTED'].includes(this.item.state);
    },
    isShownResolve() {
      return this.showResolve && (this.isUser && ['DISPUTED'].includes(this.item.state) ||
                                  !this.isUser && ['DISPUTED','ESCALATED'].includes(this.item.state));
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
    isMd() {
      return this.$store.getters.isMd || this.$store.getters.isLg;
    },
  },
  methods: {
    valDisTime(item) {
      let target = new Date(item.startAt+'Z'); // no TZ req
      target.setMinutes(target.getMinutes() + earliestDisputeAfter);
      return (new Date()) >= target;
    },
    onClose() {
      this.aType = this.actionTypes.none;
      this.$emit('close');
    },
    onAction(item, type) {
      this.aType = type;
      this.modalHeaderText = this.$t('res.et', [this.$d(utcToZonedTime(item.startAt+'Z', this.tz),'as')]);
      this.modalBtnText = this.actionButtonTexts[type];
      this.modalBtnIcon = lActionCommands[this.aType][1];
      this.$bvModal.show('resBtnAct' + this.suffix + this.id);
    },
    onConfirmation() {
      let p = null;
      switch (this.aType) {
        case this.actionTypes.accept:
        case this.actionTypes.cancel:
        case this.actionTypes.reject:
        case this.actionTypes.confirm:
        case this.actionTypes.dispute:
        case this.actionTypes.protest:
        case this.actionTypes.resolve:
          p = REST.patch('/reservations/' + this.item.uuid4, { action: lActionCommands[this.aType][0] });
          break;
        // case this.actionTypes.delete:
        //   p = REST.delete('/reservations/' + this.item.uuid4);
        //   break;
        default:
          this.$store.commit('showWarn',this.$t('dial.anos'));
          break;
      }
      
      if (p != null) {
        p.then(resp => {
            this.$store.commit('showSuccess', resp.d.message);
            if (this.saveResult) {
              this.$store.commit('setResDetails', resp.d.data);
            }
            this.$emit('success');
          }).catch(e => {
            this.$store.commit('showWarn',e.message);
          }).finally(() => {
            this.onClose();
          })
      }
    }
  },
  props: {
    type: {
      type: String,
      default: 'b-form-row'
    },
    useCols: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    isDd: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      required: true
    },
    suffix: {
      type: String,
      default: ''
    },
    showCancel: {
      type: Boolean,
      default: false
    },
    showReject: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    showAccept: {
      type: Boolean,
      default: false
    },
    showConfirm: {
      type: Boolean,
      default: false
    },
    showDispute: {
      type: Boolean,
      default: false
    },
    showResolve: {
      type: Boolean,
      default: false
    },
    showProtest: {
      type: Boolean,
      default: false
    },
    saveResult: {
      type: Boolean,
      default: false
    },
    beforeShow: {
      type: Boolean,
      default: false
    },
    beforeVariant: {
      type: String,
      default: 'primary'
    },
    beforeTo: {
      type: String,
      default: null
    },
    beforeSuffix: {
      type: String,
      default: 'bSfx'
    },
    beforeIcon: {
      type: String,
      default: null,
    },
    afterShow: {
      type: Boolean,
      default: false
    },
    afterVariant: {
      type: String,
      default: 'primary'
    },
    afterTo: {
      type: String,
      default: null
    },
    afterSuffix: {
      type: String,
      default: 'aSfx'
    },
    afterIcon: {
      type: String,
      default: null,
    },
  },
  components: {
    RButton,
    ModalYesNoGeneric,
    Icon,
  },
}
</script>
